.formInputs{
    border-radius: 25px;
    color: black !important;
}
.formInputs:focus{
    border-color: black;
}
.cardBorder{
    border-radius: 25px;
    border:1px solid #ced4da;
    height: fit-content !important;
    transition:all 0.3s ease !important;
}
.custom{
    border:1px solid #ced4da;
}
.customh1{
    color:black;
    margin-left:15px;
}
.iconbtn{
    background-color: transparent;
    border: none;
    outline: none;
    color:black
}
.iconbtn:hover{
    background-color:transparent !important;
    color : #00837a!important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
.iconbtn:active{
    background-color:transparent !important;
    color : #00837a!important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
.iconbtn:focus{
      background-color:transparent !important;
    color : #00837a!important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
.table-row:hover{
    background-color: #efefef !important;
}
.removeBut{
    width: 150px !important;
}

.menu-rounded{
    border-radius: 10px;
    padding:5px;
    width:200px;
}
.createButton:hover{
    background-color: #00837a!important;
    color:white !important;
    /*border-color: #2776b7 !important;*/
}
.createButton:focus{
    background-color: #00837a!important ;
    color:#ffffff !important;
    /*border-color: #2776b7 !important;*/
}
.createButton:active{
    background-color:  #00837a!important;
    color:#ffffff !important;
    border-color:  #00837a!important;
}
.projectLabels{
    border-color: black !important;
}

.createProjectButton{
    border-radius: 25px !important;
    width: 200px !important;

}
.createProjectButton:hover{
    background-color:  #00837a!important;
    border:1px solid  #00837a!important;
    color:white!important;

    transition:all 0.3s ease!important;
    /*width: 200px !important;*/

}

.closeCreatebutton{

    border:1px solid  #00837a!important;
    width:200px !important;
    height: 45px !important;
padding: 10px 20px !important;
text-align: center!important;
text-decoration: none!important;
/*display: inline-block;*/
font-size: 16px!important;
margin-right: 10px!important;
cursor: pointer!important;
border-radius: 25px!important;
    margin-top: 20px!important;
transition: all 0.3s ease;
}
.closeCreatebutton:hover{
    color:white !important;
    background-color: darkred !important;


}

.popup {
position: fixed;
z-index: 100000000;
left: 0;
top: 0;
width: 100%;
height: 100%;
overflow: auto;
background-color: rgba(0,0,0,0.4);
}
.popup-image {
position: fixed;
z-index: 10000;
left: 0;
top: 0;
width: 100%;
height: 100%;
overflow: auto;
background-color: rgba(0,0,0,0.4);
}
.dashboard-popup{
    z-index:9999 !important;
    position:absolute;
    height:500px !important;
}

.popup-inner {
background-color: white;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
padding: 20px;
border-radius: 5px;
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
width: 50%;
}
@media(max-width:1200px){
    .popup-inner{
        width: 70%;

    }
}
@media(max-width:600px){
    .popup-inner{
        width: 80%;

    }
}
.popup-inner-image {
background-color: white;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
padding: 20px;
border-radius: 5px;
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
width: fit-content;
height:fit-content;

    @media (max-width:400px){
     height:fit-content;
    }
    @media (min-width:400px) and (max-width:1300px){
     height:fit-content;
    }
}
.image-size{
    /*width:100%;*/
    /*height:95%;*/


}
.popup-inner-bulkForm {
background-color: white;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
padding: 20px;
border-radius: 5px;
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
width: 30%;
}
.dashboard-popup{
    height:100%;
}
.dashboard-table{
    margin-top: 25px !important;
    width : 50% !important;
}
.ticket-form{
background-color: white;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
padding: 30px;
border-radius: 5px;
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
width: 60%;

}

.popup-inner h2 {
margin-top: 0;
}

.popup-inner label {
display: block;
margin-bottom: 10px;
}

.popup-inner input {
width: 100%;
padding: 5px;
border-radius: 5px;
border: 1px solid #ccc;
margin-bottom: 10px;
}
.popup-inner-bulkForm input {
padding: 5px;
border-radius: 5px;
border: 1px solid #ccc;
margin-bottom: 10px;
}

/*.popup-inner button[type="submit"],*/
/*.popup-inner button[type="button"] {*/
/*background-color: #275552;*/
/*color: white;*/
/*border: none;*/

/*padding: 10px 20px;*/
/*text-align: center;*/
/*text-decoration: none;*/
/*display: inline-block;*/
/*font-size: 16px;*/
/*margin-right: 10px;*/
/*cursor: pointer;*/
/*    width:100px;*/
/*border-radius: 5px;*/
/*}*/

/*.popup-inner button[type="submit"]:hover,*/
/*.popup-inner button[type="button"]:hover {*/
/*background-color: #1b3a37;*/
/*}*/

/*.popup-inner button[type="submit"] {*/
/*margin-top: 10px;*/
/*}*/

.popup-inner button[type="button"] {
margin-top: 10px;
background-color: #f44336;
}

.popup-inner button[type="button"]:hover {
background-color: #da190b;
}

.popup-inner button[type="submit"]:disabled {
background-color: #bfbfbf;
color: #ffffff;
cursor: not-allowed;
}

.popup-inner button[type="button"]:disabled {
background-color: #bfbfbf;
color: #ffffff;
cursor: not-allowed;
}
.close{
    color:white;
    border: none;
    width:100px;
padding: 10px 20px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
margin-right: 10px;
cursor: pointer;
border-radius: 5px;
    margin-top: 10px;
}
.close:hover{
    background-color: darkred !important;
    color:white !important;
}

.select{
    border-color: #ced4da;
}
.bookBut:hover{
    background-color: #00837a!important

}

/*.fc .fc-daygrid-day-frame{*/
/*    height: 90px !important;*/
/*}*/
/*.fc .fc-daygrid-day-top{*/
/*    height: 35px !important;*/
/*}*/
.zzzz{
    /* background-color: #ced4da !important; */
    justify-content: center;
    
}
.top-side{
    width: 240px !important;
    /*background-color: #000000 !important;*/
}
.adviceContainer{
    border-radius: 10px;
    border:1px solid #ced4da;
    background-color: white;
}
hr{
    color: #ced4da;
}
/*.advice{*/
/*width:50%*/
/*}*/
.show {
  opacity: 1 !important;
  transition: opacity 0.3s ease !important;
}

.hide {
  opacity: 0 !important;
  transition: opacity 0.3s ease !important;
}
.auto-form-div{
    width: 48% !important;
    display: inline-block !important;
    margin-right: 1%;
}
.inputHeight{
    height: 60px !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 5px 0px !important;
}
.SelectMulti{
    width:100% !important;
    height: 80% !important;
    margin-right:1% !important;
    display:inline-block !important;
    border: 1px solid black !important;
    background:white;
}
.forgetText:hover{
    color:#275552 !important;
}

.thin-font {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
      font-weight: 50 !important;
      letter-spacing: 0.3em !important;
}
.arial {
      font-family: "Arial", Arial, sans-serif !important;
      /*font-weight: 50 !important;*/
      /*letter-spacing: 0.3em !important;*/
}
.mt-6 {
    margin-top: 5.5rem !important;
}
.mt-7 {
    margin-top: 6.5rem !important;
}
.mt-8 {
    margin-top: 7.5rem !important;
}
.gaurdadvice-desc{

      word-wrap: break-word; /* Ensures long words break to the next line */
    word-break: break-word; /* For better compatibility */
    overflow-wrap: break-word;

}
.gaurdadvice-desc-little{
    -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
}
.fieldsForm{
    width:100% !important;
    /*height:500px;*/
    overflow-y:auto !important;
    overflow-x:hidden !important;
}


.fieldsForm::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom right, #ced4da 0%, #DBB053 100%);
  border-radius: 25px;
}
/*Define the button style when being hovered over */
.fieldsForm::-webkit-scrollbar-button:hover {
  background-color: #999999;
}
.fieldsCreateForm{
    width:100% !important;
    height:500px;
    overflow-y:scroll !important;
    overflow-x:hidden !important;
}


.fieldsCreateForm::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom right, #ced4da 0%, #DBB053 100%);
  border-radius: 25px;
}
/*Define the button style when being hovered over */
.fieldsCreateForm::-webkit-scrollbar-button:hover {
  background-color: #999999;
}
.textarea-input {
    height: auto;
    min-height: 50px;
    overflow-y:scroll !important;
}
.textarea-input::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Define the thumb style */
.textarea-input::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom right, #ced4da 0%, #DBB053 100%);
  border-radius: 25px;
}

/* Define the track style */
.textarea-input::-webkit-scrollbar-track {
  background-color: #ddd;
  border: 1px solid #ccc;
    border-radius: 25px;
}

/* Define the button style */
.textarea-input::-webkit-scrollbar-button {
  background-color: #ddd;
  border-radius: 5px;
}

/* Define the button style when being hovered over */
.textarea-input::-webkit-scrollbar-button:hover {
  background-color: #999999;
}


.close-icon {
  position: absolute;
  top: 0.1%;
  right: 0.1%;
  background-color: rgba(255, 0, 0, 0.88);
    border-radius:2px;
  border: none;
  color: #000;
  font-size: 20px;
  cursor: pointer;
    transition:all ease 0.1s;
  z-index: 1;
}
.close-icon:hover{
    background-color:darkred !important;
}
.scrollable-droppable {
  max-height: 400px; /* Set the desired maximum height */
  overflow-y: auto;
}
.custom-select-input[disabled]{
    border-color:lightgrey !important;
    background-color: #f3f3f3 !important;
}
.pointer{
    cursor:pointer !important;
}
.tickets-table{
    background-color:white !important;
    border-radius:25px !important;
    border: 1px solid #ced4da !important;
}
.height-breachEdit{
    height:350px;
}
.popup-spinner {
position: fixed;
z-index: 1;
left: 0;
top: 0;
width: 100%;
height: 100%;
overflow: auto;
background-color: rgba(0,0,0,0.4);
}

.popup-inner-spinner {
background-color: white;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
padding: 20px;
border-radius: 5px;
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
}

.ticketsDetialForm{
    width:100%;
}
.custom-checkbox {
  display: flex !important;
  /*align-items: center !important;  !* Vertically align the checkbox and label *!*/
  margin-right: 10px !important;   /* Adjust the right margin as needed */
}

.custom-checkbox .custom-control-input {
  width: 18px !important;  /* Set the width of the checkbox input */
  height: 18px !important; /* Set the height of the checkbox input */
  margin-right: 5px !important;  /* Adjust the right margin as needed */
}
.apexcharts-pie-label {
    fill: #FFFFFF !important; /* Set the color to white */
}

.react-select input{
    height:20px !important;
    overflow-y:auto !important;
}
.react-select .css-13cymwt-control{
    height:20px !important;
    overflow-y:auto !important;
}


.css-1hb7zxy-IndicatorsContainer{
    align-items:start !important;
    margin-left:30%;
    position:fixed !important;
}
.css-1xc3v61-indicatorContainer{
    padding:8px 0px 8px 0px !important;
}
/*.react-select{*/
/*    height:50px !important;*/
/*    overflow-y:auto !important;*/
/*}*/
.react-select input{
    /*max-height:150px !important;*/
    overflow-y:auto !important;
}
.react-select input::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Define the thumb style */
.react-select input::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom right, #ced4da 0%, #058fe0 100%);
  border-radius: 25px;
}

/* Define the track style */
.react-select input::-webkit-scrollbar-track {
  background-color: #ddd;
  border: 1px solid #ccc;
    border-radius: 25px;
}

/* Define the button style */
.react-select input::-webkit-scrollbar-button {
  background-color: #ddd;
  border-radius: 5px;
}

/* Define the button style when being hovered over */
.react-select input::-webkit-scrollbar-button:hover {
  background-color: #999999;
}


.x-select{
    margin-left: -10px;
    margin-top: 35px;
}
.as-button{
    outline: none;
	background: none;
	border: none;
	outline: none;

}
.enhanceParagraph{
    cursor:pointer;
    font-weight:500;
}
.enhanceParagraph:hover{
    color: #1778d7;
}
.mentionedUser{
    color:#1778d7;
    cursor: pointer;
    font-weight: bold;
    /* font-size: medium; */
    text-transform: capitalize;
}
.comment_user{
    text-transform: capitalize;
    font-size: medium;
    cursor: pointer;
}
.profile-name{
    text-transform: capitalize;
    /* font-weight: bold !important; */
    font-size: 18px;
    color: gray;
}
.profile-names{
    font-weight: bold !important;
    font-family: roboto;
}
.profile-popup{
    width: 60%;
}
.dropdown-toggle::after{
    display:none !important;
}
.alert {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.alert-show {
    opacity: 1;
}
.p-multiselect-panel {
    z-index: 100000000 !important;
}

.bg-grey-blue{
     color:white !important;
    background-color: #589097 !important;
}

.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
    border-radius:10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}
.swal2-popup .custom-confirm-button {
    background-color: #d33 !important; /* Red color for the confirm button */
    border-color: #d33 !important;     /* Red border */
}

.swal2-popup .custom-cancel-button {
    background-color: #3085d6 !important; /* Blue color for the cancel button */
    border-color: #3085d6 !important;    /* Blue border */
}
/*.dropdown:hover .dropbtn {*/
/*  background-color: #3e8e41;*/
/*}*/

.scrollable-ticket-section{
    max-height:400px;
    overflow-y:auto;
    scroll-behavior: smooth;

}
.active-project{
    background-color: #ecfef3;
    color: #08b558;
    font-weight: bold;
    padding: 5px;
    border-radius: 50px;
}
.inactive-project{
    background-color: rgba(225, 119, 119, 0.07);
    color: #af1a38;
    font-weight: bold;
     padding: 5px;
    border-radius: 50px;
}
.low-severity {
    color: #08b558;
    font-weight: bold;
}

.medium-severity {
    color: #bbc106;
    font-weight: bold;
}

.high-severity {
    color: #ffad00;
    font-weight: bold;
}

.critical-severity{
    color: #af1a38;
    font-weight: bold;
}

.dialog-width{
    width:30%
}
@media (min-width:501px) and (max-width : 800px) {
    .dialog-width{
        width:50%;
    }
}
@media (max-width : 500px) {
    .dialog-width{
        width:80%;
    }
}